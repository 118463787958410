import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import React, { FunctionComponent, ReactNode } from "react"
import { BasicSection, ImageOrientation, Section } from "../../entities/section"

export type BasicSectionProps = {
  section: BasicSection
}

export const BasicSectionComponent: FunctionComponent<BasicSectionProps> = ({
  section,
}) => {
  const content: ReactNode = (
    <div className="content">
      {documentToReactComponents(JSON.parse(section.content.raw))}
    </div>
  )

  switch (section.imageOrientation) {
    case ImageOrientation.Right:
      return (
        <>
          <div className="columns is-variable is-6">
            <div className="column is-three-quarters">{content}</div>
            <div className="column">
              <figure className="image">
                <img className="" src={section.image.fluid.src} />
              </figure>
            </div>
          </div>
        </>
      )
    case ImageOrientation.Left:
      return (
        <>
          <div className="columns is-variable is-6">
            <div className="column">
              <figure className="image">
                <img className="" src={section.image.fluid.src} />
              </figure>
            </div>
            <div className="column is-three-quarters">{content}</div>
          </div>
        </>
      )
    default:
    case ImageOrientation.None:
      return <>{content}</>
  }
}
