import { graphql } from "gatsby"
import React from "react"
import Navbar from "../components/navbar/navbar"
import { SectionComponent } from "../components/section/section"
import SEO from "../components/seo"
import { Page } from "../entities/page"

const PageComponent = ({ data }: any) => {
  const pageData: Page = data.allContentfulPage.nodes[0]
  console.log(pageData)
  return (
    <>
      <SEO
        title={pageData.title}
        lang="nl"
        meta={[]}
        description={pageData.seoDescription}
      />
      <Navbar />
      {pageData.sections.map(section => (
        <SectionComponent key={section.id} section={section}></SectionComponent>
      ))}
    </>
  )
}

export default PageComponent

export const pageQuery = graphql`
  query FullPageQuery($slug: String = "") {
    allContentfulPage(filter: { slug: { eq: $slug } }) {
      nodes {
        slug
        title
        seoDescription
        sections {
          ... on ContentfulSection {
            id
            content {
              raw
            }
            image {
              fluid {
                src
              }
            }
            imageOrientation
            title
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulBrandList {
            id
            title
            brands {
              fluid {
                src
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`
