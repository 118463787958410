import React, { FunctionComponent } from "react"
import { BrandListSection } from "../../entities/section"

export type BrandListSectionProps = {
  section: BrandListSection
}

export const BrandListSectionComponent: FunctionComponent<BrandListSectionProps> = ({
  section,
}) => {
  return (
    <>
      <div className="columns is-vcentered is-multiline">
        {section.brands.map((brand, index) => (
          <div key={index} className="column">
            <figure className="image">
              <img
                className=""
                style={{ maxWidth: "10vw" }}
                src={brand.fluid.src}
              />
            </figure>
          </div>
        ))}
      </div>
    </>
  )
}
