import React, { FunctionComponent, KeyboardEvent, useState } from "react"
import { BasicSection, BrandListSection, Section } from "../../entities/section"
import { BasicSectionComponent } from "./basic-section"
import { BrandListSectionComponent } from "./brand-list-section"

export type SectionProps = {
  section: Section
}

const renderDetails = (section: Section) => {
  switch (section.sys.contentType.sys.id) {
    case "section":
      return (
        <BasicSectionComponent
          section={section as BasicSection}
        ></BasicSectionComponent>
      )
    case "brandList":
      return (
        <BrandListSectionComponent
          section={section as BrandListSection}
        ></BrandListSectionComponent>
      )
    default:
      console.warn("Unknown content type", section)
      return <div></div>
  }
}

export const SectionComponent: FunctionComponent<SectionProps> = ({
  section,
}) => {
  return (
    <section className="section">
      <div className="container">
        <h1 className="title">{section.title}</h1>
        {renderDetails(section)}
      </div>
    </section>
  )
}
