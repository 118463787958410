import { ContentfulImage } from "./image"

export interface Section {
  id: string
  title: string
  sys: {
    contentType: {
      sys: {
        id: "section" | "brandList"
      }
    }
  }
}

export enum ImageOrientation {
  Left = "left",
  Right = "right",
  None = "none",
}

export interface BasicSection extends Section {
  content: {
    raw: string
  }
  image: ContentfulImage
  imageOrientation: ImageOrientation
  sys: {
    contentType: {
      sys: {
        id: "section"
      }
    }
  }
}

export interface BrandListSection extends Section {
  brands: ContentfulImage[]
  sys: {
    contentType: {
      sys: {
        id: "brandList"
      }
    }
  }
}
